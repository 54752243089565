import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';

import configureStore from './store';
import App from './App';
import * as serviceWorker from './registerServiceWorker';
import 'react-app-polyfill/stable';

if (process.env.NODE_ENV !== 'development') {
  const bugsnagClient = bugsnag(process.env.REACT_APP_BUGSNAG_KEY);
  bugsnagClient.use(bugsnagReact, React);

  const ErrorBoundary = bugsnagClient.getPlugin('react');

  ReactDOM.render(
    <ErrorBoundary>
      <Provider store={configureStore()}>
        <App />
      </Provider>
    </ErrorBoundary>,
    document.getElementById('root')
  );
} else {
  ReactDOM.render(
    <Provider store={configureStore()}>
      <App />
    </Provider>,
    document.getElementById('root')
  );
}

serviceWorker.register();
