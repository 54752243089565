import React from 'react';
import OfferCard from 'components/molecules/offerCard/index.jsx';
import { connect } from 'react-redux';

class BestOffersMobile extends React.Component {
  getBestOffers() {
    const { offersByBrand } = this.props;
    const bestOffers = [];

    for (const id in offersByBrand) {
      if (offersByBrand[id].best.length) {
        bestOffers.push(offersByBrand[id].best[0]);
      }
    }

    return bestOffers;
  }

  render() {
    const offers = this.getBestOffers();

    return (
      <React.Fragment>
        {offers.map(offer => (
          <React.Fragment key={offer.id}>
            <OfferCard offer={offer} />
            <br />
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    offersByBrand: state.offer.byBrand
  };
};

export default connect(mapStateToProps, null)(BestOffersMobile);
