import React from 'react';
import { Link } from 'react-router-dom';

import './RowButton.scss';

export default class RowButton extends React.Component {
  render() {
    const { text, link, disabled, icon, external, onClick } = this.props;

    let className =
      'row-button ' +
      (disabled ? ' row-button-disabled' : '') +
      (icon ? ' row-button-icon' : '');

    var rowLinkElement;

    if (external) {
      rowLinkElement = (
        <a
          className={className}
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          disabled={disabled}
        >
          {text}
        </a>
      );
    } else {
      rowLinkElement = (
        <Link className={className} to={link} disabled={disabled}>
          {text}
        </Link>
      );
    }

    return <div onClick={onClick}>{rowLinkElement}</div>;
  }
}
