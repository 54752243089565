import React from 'react';
import { Link } from 'react-router-dom';
import './ModalHeader.scss';

export default class ModalHeader extends React.Component {
  render() {
    const { type, title, link, units, unitsImage, model, onClose, dark } = this.props;

    let className =
      'modal-header' +
      (type ? ' modal-header-' + type : '') +
      (units ? ' modal-header-units' : '') +
      (dark ? ' modal-header-dark' : '');

    let headerContent;
    if (units) {
      headerContent = (
        <div className="container">
          <div className="col col-two">
            <img
              src={unitsImage}
              alt=""
              className="modal-header__units-image"
            />
          </div>
          <div className="col col-five">
            <h4>{model}</h4>
            <h2>{title}</h2>
          </div>
          <div className="col col-five modal-header__buy-text">
            <h5>Reservar Online es fácil y rápido</h5>
            <p className="p-s">
              Solo pagas una seña con tu tarjeta de crédito. Si te
              arrepientes, dentro de las 48 horas, te devolvemos el 100% de la
              seña.
            </p>
          </div>
        </div>
      );
    } else {
      headerContent = <div className="modal-header__title">{title}</div>;
    }

    return (
      <div className={className}>
        {onClose && (
          <a
            className="modal-header__link"
            href={link}
            onClick={e => {
              e.preventDefault();
              onClose();
            }}
          >
            {type}
          </a>
        )}
        {!onClose && (
          <Link className="modal-header__link" to={link}>
            {type}
          </Link>
        )}
        {headerContent}
      </div>
    );
  }
}
