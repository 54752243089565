import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Responsive from 'react-responsive';
import Button from 'components/atoms/button/index.jsx';
import Header from 'components/organisms/header/index.jsx';
import MobileNavigation from 'components/organisms/mobileNavigation/index.jsx';
import Footer from 'components/organisms/footer/index.jsx';
import Hero from 'components/organisms/hero/index.jsx';
import Tabs from 'components/molecules/tabs/index.jsx';
import PlanCard from 'components/molecules/planCard/index.jsx';
//import PlanCardYacopini from 'components/molecules/planCardYacopini/index.jsx';
import MobileTabs from 'components/molecules/mobileTabs/index.jsx';
import Loader from 'components/atoms/loader';
import StickyNav from 'components/molecules/stickyNav/index.jsx';
import StoriesBlock from 'components/organisms/storiesBlock';
import {
  switchToVolkswagen,
  switchToChevrolet,
  switchToNissan
} from 'actions/brand';
import savingPlanActions from 'actions/savingPlan';
import familyActions from 'actions/family';
import vwHero from 'resources/img/vw/vw_plans_hero@2x.jpg';
import gmHero from 'resources/img/gm/gm_plans_hero@2x.jpg';
import nissanHero from 'resources/img/nissan/nissan_models_hero@2x.jpg';
import brands from 'config/brands';

import './Plans.scss';

const DesktopHeader = props => <Responsive {...props} minWidth={1024} />;
const MobileHeader = props => <Responsive {...props} maxWidth={1023} />;

class Plans extends React.Component {
  state = {};
  getActiveBrandIndex() {
    switch (this.props.brandId) {
      case brands.chevrolet.id:
        return 1;
      case brands.nissan.id:
        return 2;
      case brands.volkswagen.id:
      default:
        return 0;
    }
  }

  getHero() {
    switch (this.props.brandId) {
      case brands.chevrolet.id:
        return gmHero;
      case brands.nissan.id:
        return nissanHero;
      case brands.volkswagen.id:
      default:
        return vwHero;
    }
  }

  componentDidMount() {
    const {
      brandName,
      match: {
        params: { brand }
      }
    } = this.props;

    if (brand === brandName) {
      this.props.fetch();
      return;
    }

    switch (brand) {
      case 'volkswagen':
        this.props.switchToVolkswagen();
        break;
      case 'chevrolet':
        this.props.switchToChevrolet();
        break;
      case 'nissan':
        this.props.switchToNissan();
        break;
      default:
        this.props.switchToVolkswagen();
        break;
    }
  }

  render() {
    const {
      savingsPlanName,
      theme,
      brandId,
      requesting,
      plans
    } = this.props;
    const plansSections = [
      {
        name: 'Todos',
        onClick: this.seeAllPlans
      },
      {
        name: 'Autoahorro Volkswagen',
        onClick: () => this.setState({ activePlans: 'brand' })
      },
      {
        name: 'Planes Yacopini',
        onClick: () => this.setState({ activePlans: 'yacopini' })
      }
    ];
    const tabs = [
      {
        name: 'Volkswagen',
        onClick: () => this.props.switchToVolkswagen()
      },
      {
        name: 'Chevrolet',
        onClick: () => this.props.switchToChevrolet()
      },
      {
        name: 'Nissan',
        onClick: () => this.props.switchToNissan()
      }
    ];

    const stories = this.props.parameters?.stories ? this.props.parameters.stories.filter(story => story.category_string === 'Planes de Ahorro') : [];

    return (
      <div className="plans">
        <Helmet>
          <title>{savingsPlanName}</title>
          <meta
            name="description"
            content="Territorio Yacopini - Concesionario oficial Volkswagen, Chevrolet, Nissan y Ducati"
          />
          <meta
            name="keywords"
            content="Territorio, Yacopini, Volkswagen, Chevrolet, Nissan, Ducati, vehiculos, autos, compra, 0km, planes de ahorro, autoahorro"
          />
        </Helmet>
        <MobileHeader>
          <MobileTabs title="Nuestras Marcas" tabs={tabs} active={1} />
        </MobileHeader>
        <DesktopHeader>
          <Header active={3} />
        </DesktopHeader>
        <MobileHeader>
          <MobileNavigation active={3} />
        </MobileHeader>
        <DesktopHeader>
          <div className=" section section-small section-center">
            <div className="container ">
              <div className="col col-twelve">
                <Tabs
                  title="Nuestras marcas"
                  tabs={tabs}
                  active={this.getActiveBrandIndex()}
                />
              </div>
            </div>
          </div>
        </DesktopHeader>
        <Hero type="image" big image={this.getHero()} imageAlt="Volkswagen">
          <div className="col col-five col-m-twelve col-sd-six">
            <h1>{savingsPlanName}</h1>
            {brandId === brands.volkswagen.id && (
              <p className="p-b">
                Autoahorro Volkswagen te da la posibilidad de acceder, mediante
                el pago de cuotas mensuales en pesos, sujetas a variaciones de
                lista de precios, y de bajo monto, sin anticipos ni requisitos
                de ingreso, a los vehículos de la amplia gama de modelos que
                Volkswagen pensó para vos.
              </p>
            )}
            {brandId === brands.chevrolet.id && (
              <p className="p-b">
                Plan Chevrolet es un plan en pesos, sin intereses, transparente,
                sin sorpresas y cuenta con el respaldo de General Motors de
                Argentina: la compañía automotriz número uno del país.
              </p>
            )}
            {brandId === brands.nissan.id && (
              <p className="p-b">
                Nissan te brinda transparencia y flexibilidad para alcanzar el
                vehículo que deseas planificando la compra de tu 0km en cuotas
                accesibles y en pesos.
              </p>
            )}

            <Button
              text="Ver Planes"
              variant="main"
              color={theme}
              onClick={() =>
                this.plansSection?.scrollIntoView({ behavior: 'smooth' })
              }
            />

            {brandId === brands.volkswagen.id && (
              <React.Fragment>
                <span className="plan-pay-link">
                  <Link to="/pagar-plan">
                    <Button text="Pagar plan online" variant="main" />
                  </Link>
                </span>
                {/* <span className="plan-pay-link">
                  <Link to="/">
                    <Arrowlink
                      text="Conoce nuestros planes propios"
                      small
                      color="blue"
                    />
                  </Link> </span>*/}
              </React.Fragment>
            )}
            {brandId === brands.chevrolet.id && (
              <span className="plan-pay-link">
                <Link to="/pagar-plan">
                  <Button text="Pagar plan online" variant="main" />
                </Link>
              </span>
            )}
          </div>
        </Hero>
        {brandId === brands.volkswagen.id && (
          <MobileHeader>
            <MobileTabs tabs={plansSections} />
          </MobileHeader>
        )}

        <StoriesBlock title="Planes de Ahorro" stories={stories} /> 

        <div
          className=" section section-center section-white section-relative plans__plans-list"
          ref={section => {
            this.plansSection = section;
          }}
        >
          {brandId === brands.volkswagen.id && (
            <DesktopHeader>
              <StickyNav big sections={plansSections} />
            </DesktopHeader>
          )}
          <div className="container ">
            <div className="col col-six col-m-twelve">
              <h2>{savingsPlanName}</h2>
            </div>
          </div>
        </div>
        <div className=" section section-center section-white">
          <div className="container">
            {requesting && <Loader />}
            {plans.map(plan => (
              <div className="col col-six col-m-twelve" key={plan.id}>
                <PlanCard
                  plan={plan}
                  image={plan.image}
                  link={plan.getSubscriptionLink(brandId)}
                  features="Plan Features"
                />
              </div>
            ))}
          </div>
        </div>

        {/* YACOPINI PLANS
        {brandId === brands.volkswagen.id && (
          <React.Fragment>
            <div className=" section section-center section-white section-relative plans__plans-list">
              <div className="container ">
                <div className="col col-six col-m-twelve">
                  <h2>Planes Yacopini</h2>
                  <p>
                    Explicación de que son los planes de Yacopini y como son
                    diferentes de los otros planes.
                  </p>
                </div>
              </div>
            </div>
            <div className=" section section-center section-white">
              <div className="container">
                {requesting && <Loader />}
                {plans.map(plan => (
                  <div className="col col-six col-m-twelve" key={plan.id}>
                    <PlanCardYacopini
                      plan={plan}
                      image={plan.image}
                      link={plan.getSubscriptionLink(brandId)}
                      features="Plan Features"
                    />
                  </div>
                ))}
              </div>
            </div>
          </React.Fragment>
        )} */}
        <div className=" section section-center  section-white">
          <div className="container">
            <div className="col col-twelve col-padded" />
            <div className="col col-twelve col-padded">
              <p className="p-disclamer">
                * Valor de referencia, no incluye gastos de seguro.
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    brandId: state.brand.active,
    brandName: state.brand.name,
    savingsPlanName: state.brand.savingsPlanName,
    theme: state.brand.theme,
    plans: state.savingPlan.all,
    requesting: state.savingPlan.requesting,
    parameters: state.parameter.all
  };
};

const mapDispatchToProps = dispatch => {
  return {
    switchToVolkswagen: () => {
      dispatch(switchToVolkswagen());
      dispatch(savingPlanActions.fetchAll());
      dispatch(familyActions.fetchAll());
    },
    switchToChevrolet: () => {
      dispatch(switchToChevrolet());
      dispatch(savingPlanActions.fetchAll());
      dispatch(familyActions.fetchAll());
    },
    switchToNissan: () => {
      dispatch(switchToNissan());
      dispatch(savingPlanActions.fetchAll());
      dispatch(familyActions.fetchAll());
    },
    fetch: () => dispatch(savingPlanActions.fetchAll())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Plans);
