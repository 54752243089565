export const switchToVolkswagen = () => ({
  type: 'BRAND_SWITCH_TO_VOLKSWAGEN'
});

export const switchToChevrolet = () => ({
  type: 'BRAND_SWITCH_TO_CHEVROLET'
});

export const switchToNissan = () => ({
  type: 'BRAND_SWITCH_TO_NISSAN'
});

export const switchToBAIC = () => ({
  type: 'BRAND_SWITCH_TO_BAIC'
});

export const switchToBrandName = brandName => ({
  type: 'BRAND_SWITCH_TO_BRAND_NAME',
  brandName: brandName
});
