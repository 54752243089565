import brands from 'config/brands';

export default class SavingPlan {
  constructor(data = {}) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        this[key] = data[key];
      }
    }
  }

  get url_name() {
    return encodeURI(this.name.toLowerCase().replace(/[\s/.-]+/g, "-"))
  }

  get url_family_name() {
    return this.family.toLowerCase().replace(/[\s/-]+/g, '-');
  }

  getSubscriptionLink(brandId) {
    switch (brandId) {
      case brands.volkswagen.id:
        return `https://www.vwyacopini.com.ar/autoahorro/subscripcion/${this.url_family_name}/${this.id}`;

      case brands.chevrolet.id:
        return `https://yacopinigm.com.ar/plan-chevrolet/subscripcion/${this.id}/${this.url_name}`;

      case brands.nissan.id:
        return `https://yacopininissan.com.ar/planes-de-ahorro/suscribirse/${this.id}/${this.url_name}`;

      default:
        console.error(`Brand ${brandId} not found`);

        return '/';
    }
  }
}
