import React from 'react';
import './Card.scss';

export default class Card extends React.Component {
  componentDidMount() {}

  render() {
    const { nopadding } = this.props;
    let className = 'card ' + (nopadding ? ' card-nopadding' : '');

    return <div className={className}>{this.props.children}</div>;
  }
}
