const brands = {
  volkswagen: {
    id: 1,
    name: 'Volkswagen',
    savingsPlanName: 'Autoahorro Volkswagen',
    theme: 'blue'
  },
  chevrolet: {
    id: 2,
    name: 'Chevrolet',
    savingsPlanName: 'Plan Chevrolet',
    theme: 'yellow'
  },
  nissan: {
    id: 4,
    name: 'Nissan',
    savingsPlanName: 'Nissan plan de ahorro',
    theme: 'red'
  },
  territorio: {
    id: 5,
    name: 'Territorio Yacopini',
    theme: 'yellow'
  },
  baic: {
    id: 6,
    name: 'BAIC',
    theme: 'red'
  },
};

const handler = {
  get: function(obj, prop) {
    return prop in obj ? obj[prop] : {};
  }
};

const proxy = new Proxy(brands, handler);

export default proxy;

export const getBrandName = brandId => {
  switch (brandId) {
    case brands.volkswagen.id:
      return brands.volkswagen.name;
    case brands.chevrolet.id:
      return brands.chevrolet.name;
    case brands.nissan.id:
      return brands.nissan.name;
    case brands.baic.id:
      return brands.baic.name;
    default:
      console.error(`Invalid brand ${brandId}`);
      return '';
  }
};
