import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Footer from 'components/organisms/footer/index.jsx';
import MobileHeader from 'components/organisms/mobileHeader/index.jsx';
import RowButton from 'components/atoms/rowButton/index.jsx';
import y_active from 'resources/img/y-active.svg';
import y_inactive from 'resources/img/y-inactive.svg';
import models_active from 'resources/img/models-active.svg';
import models_inactive from 'resources/img/models-inactive.svg';
import promotions_active from 'resources/img/promotions-active.svg';
import promotions_inactive from 'resources/img/promotions-inactive.svg';
import plans_active from 'resources/img/plans-active.svg';
import plans_inactive from 'resources/img/plans-inactive.svg';
import more_active from 'resources/img/more-active.svg';
import more_inactive from 'resources/img/more-inactive.svg';
import routes from 'config/routes';
import { getContacts, department } from 'services/contactMethod';
import './MobileNavigation.scss';
import './MoreNavigation.scss';

class MobileNavigation extends React.Component {
  state = {
    more: false
  };

  render() {
    const { active } = this.props;
    const { more } = this.state;

    const setMoreFalse = () => this.setState({ more: false });
    const setMoreTrue = () => this.setState({ more: true });

    const methods = getContacts(this.props.parameters, department.ADMIN);

    let videocallLink = '';
    let waNumber = '';
    if (methods.length > 0) {
      videocallLink = methods[0].zoom_link;
      waNumber = methods[0].whatsapp_number;
    }

    if (more) {
      return (
        <div className="more-navigation">
          <MobileHeader
            title="Más de Territorio Yacopini"
            type="close"
            onClose={setMoreFalse}
          />

          <div className="section section-white" />
          <div className="covid-message"></div>
          {videocallLink.includes('zoom') && (
            <RowButton
              text="Llamanos por Zoom"
              link={videocallLink}
              icon
              onClick={setMoreFalse}
              external
            />
          )}
          {videocallLink.includes('skype') && (
            <RowButton
              text="Llamanos por Skype"
              link={videocallLink}
              icon
              onClick={setMoreFalse}
              external
            />
          )}
          {parseInt(videocallLink) > 0 && (
            <RowButton
              text="Llamanos por WhatsApp"
              link={`tel:${parseInt(videocallLink)}`}
              icon
              onClick={setMoreFalse}
              external
            />
          )}
          {waNumber > 0 && (
            <RowButton
              text="Contactanos por WhatsApp"
              link={`https://web.whatsapp.com/send?phone=${waNumber}`}
              icon
              onClick={setMoreFalse}
              external
            />
          )}
          <RowButton
            text="Postventa"
            link="/postventa"
            icon
            onClick={setMoreFalse}
          />
          <RowButton
            text="Contacto"
            link="/contacto"
            onClick={setMoreFalse}
            icon
          />
          <RowButton
            text="Usados"
            link="https://territorioyacopini.com.ar/usados/"
            icon
            external
          />
          <RowButton
            text="Turnos Chevrolet"
            link="https://www.territorioyacopini.com.ar/solicitar-turno/chevrolet"
            icon
            external
          />
          <RowButton
            text="Turnos Volkswagen"
            link="https://www.territorioyacopini.com.ar/solicitar-turno/volkswagen"
            icon
            external
          />
          <RowButton
            text="Turnos Nissan"
            link="https://www.territorioyacopini.com.ar/solicitar-turno/nissan"
            icon
            external
          />

          <Footer alt />
        </div>
      );
    }

    return (
      <div className="mobile-navigation">
        <div
          className={
            'mobile-navigation__link' +
            (active === 0 ? ' mobile-navigation__link-active' : '')
          }
        >
          <Link className="mobile-navigation__button" to="/">
            Inicio
          </Link>
          <div className="mobile-navigation__link__image mobile-navigation__link__active">
            <img src={y_active} alt="Inicio" />
          </div>
          <div className="mobile-navigation__link__image mobile-navigation__link__inactive">
            <img src={y_inactive} alt="Inicio" />
          </div>
        </div>

        <div
          className={
            'mobile-navigation__link' +
            (active === 1 ? ' mobile-navigation__link-active' : '')
          }
        >
          <Link className="mobile-navigation__button" to={routes.families}>
            Modelos
          </Link>
          <div className="mobile-navigation__link__image mobile-navigation__link__active">
            <img src={models_active} alt="Modelos" />
          </div>
          <div className="mobile-navigation__link__image mobile-navigation__link__inactive">
            <img src={models_inactive} alt="Modelos" />
          </div>
        </div>
        <div
          className={
            'mobile-navigation__link' +
            (active === 2 ? ' mobile-navigation__link-active' : '')
          }
        >
          <Link className="mobile-navigation__button" to="/oportunidades">
            Oportunidades
          </Link>
          <div className="mobile-navigation__link__image mobile-navigation__link__active">
            <img src={promotions_active} alt="oportunidades" />
          </div>
          <div className="mobile-navigation__link__image mobile-navigation__link__inactive">
            <img src={promotions_inactive} alt="oportunidades" />
          </div>
        </div>
        <div
          className={
            'mobile-navigation__link' +
            (active === 3 ? ' mobile-navigation__link-active' : '')
          }
        >
          <Link className="mobile-navigation__button" to="/planes-de-ahorro">
            Planes de ahorrro
          </Link>
          <div className="mobile-navigation__link__image mobile-navigation__link__active">
            <img src={plans_active} alt="Planes de Ahorro" />
          </div>
          <div className="mobile-navigation__link__image mobile-navigation__link__inactive">
            <img src={plans_inactive} alt="Planes de Ahorro" />
          </div>
        </div>
        <div
          className={
            'mobile-navigation__link' +
            (active === 4 ? ' mobile-navigation__link-active' : '')
          }
        >
          <a
            className="mobile-navigation__button"
            onClick={e => {
              e.preventDefault();
              setMoreTrue();
            }}
          >
            Más opciones
          </a>
          <div className="mobile-navigation__link__image mobile-navigation__link__active">
            <img src={more_active} alt="Más" />
          </div>
          <div className="mobile-navigation__link__image mobile-navigation__link__inactive">
            <img src={more_inactive} alt="Más" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    parameters: state.parameter.all
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileNavigation);
