const defaultState = {
  checkingPromotion: false,
  promotion: undefined,
  availablePromotion: null
};

export default function purchaseReducer(state = defaultState, action) {
  switch (action.type) {
    case 'PROMOTION_CODE_REQUESTED':
      return {
        ...state,
        checkingPromotion: true,
        promotion: undefined
      };

    case 'PROMOTION_CODE_REQUEST_FAILED':
      return {
        ...state,
        checkingPromotion: false,
        promotion: null
      };

    case 'PROMOTION_CODE_SUCCESSFUL':
      return {
        ...state,
        checkingPromotion: false,
        promotion: action.promotion
      };

    case 'PROMOTION_CODE_DISCOVER_REQUESTED':
      return {
        ...state,
        availablePromotion: null
      };

    case 'PROMOTION_CODE_DISCOVER_SUCCESSFUL':
      return {
        ...state,
        availablePromotion: action.promotion
      };

    default:
      return state;
  }
}
