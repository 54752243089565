import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import FlowHeader from 'components/molecules/flowHeader/index.jsx';
import BalanceInformation from 'components/molecules/balanceInformation/index.jsx';
import LinkingPosPayment from './LinkingPos';
import { parseRoute } from 'config/routes';

import '../DigitalDealership.scss';

class Payment extends React.Component {
  render() {
    const { vehicle, operation } = this.props;
    const { operation_code, brand } = this.props.match.params;

    if (!operation || !vehicle) {
      return (
        <Redirect
          to={parseRoute('dealership_resume', {
            operation_code
          })}
        />
      );
    }

    let bookingPrice = 200000;
    if (this.props.parameter) {
      bookingPrice = this.props.parameter.services[operation.brand_id].booking_price || 200000;
    }

    return (
      <div className="digital-dealership digital-dealership__payment">
        <FlowHeader
          small
          title="Concesionario Digital"
          subtitle={`Reservando ${vehicle.name}`}
        />
        <BalanceInformation
          operation={operation}
          cashAmount={operation.cash_amount}
          vehicle={vehicle}
        />
        <div className="section">
          <div className="container">
            <div className="col col-two col-not-mobile " />
            <div className="col col-eight col-m-twelve ">
              <h5>Compra Online</h5>
              <h2>4. Pago de seña</h2>
              <div className="digital-dealership__payment--linkingpos">
                <LinkingPosPayment operation={operation} amount={bookingPrice}  />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    operation: state.dealership.operation,
    vehicle: state.stock.selected,
    parameter: state.parameter.all,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
