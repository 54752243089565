import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import Responsive from 'react-responsive';
import MobileNavigation from 'components/organisms/mobileNavigation/index.jsx';
import Header from 'components/organisms/header/index.jsx';
import Footer from 'components/organisms/footer/index.jsx';
import Hero from 'components/organisms/hero/index.jsx';
import OfferGroupCard from 'components/molecules/offerGroupCard/index.jsx';
import StickyNav from 'components/molecules/stickyNav/index.jsx';
import Textfield from 'components/molecules/textfield/index.jsx';
import Arrowlink from 'components/atoms/arrowLink/index.jsx';
import Button from 'components/atoms/button/index.jsx';
import MobileTabs from 'components/molecules/mobileTabs/index.jsx';
import Loader from 'components/atoms/loader';
import Units from 'components/organisms/units';
import brands, { getBrandName } from 'config/brands';
import familiesActions from 'actions/families';

import './Promotions.scss';

const Desktop = props => <Responsive {...props} minWidth={1152} />;
const DesktopHeader = props => <Responsive {...props} minWidth={1024} />;
const MobileHeader = props => <Responsive {...props} maxWidth={1023} />;

class Promotions extends React.Component {
  constructor(props) {
    super(props);

    const activeBrandParam = (props.match.params.brand || '').toLowerCase();
    let activeBrand = activeBrandParam !== '' && brands[activeBrandParam] ? brands[activeBrandParam].id : null;

    this.state = {
      activeBrand,
      units: null,
      showFamily: null
    };

    this.hideUnits = this.hideUnits.bind(this);
    this.seeAllBrands = this.seeAllBrands.bind(this);
  }

  componentDidMount() {
    if (!this.familiesLoaded()) {
      this.props.fetchFamily(brands.volkswagen.id);
      this.props.fetchFamily(brands.chevrolet.id);
      this.props.fetchFamily(brands.nissan.id);
      this.props.fetchFamily(brands.baic.id);
    }
  }

  seeAllBrands() {
    this.setState({ activeBrand: null });
  }

  render() {
    const {
      vwOffers,
      gmOffers,
      nissanOffers,
      baicOffers,
      vwOffersGroups,
      gmOffersGroups,
      nissanOffersGroups,
      baicOffersGroups,
      theme
    } = this.props;
    const { activeBrand, showFamily, units } = this.state;

    const offerAmount = vwOffers.length + gmOffers.length + nissanOffers.length + baicOffers.length;

    if (!this.familiesLoaded()) {
      return this.renderLoading();
    }

    if (showFamily) {
      return (
        <Units
          family={showFamily}
          vehicles={units}
          onClose={this.hideUnits}
          theme={theme}
          brand={getBrandName(units[0].brand_id)}
          offer
        />
      );
    }

    const brandSections = [
      {
        name: 'Todos',
        onClick: this.seeAllBrands,
        path: '/oportunidades',
      },
      {
        name: 'Chevrolet',
        onClick: () => this.setState({ activeBrand: brands.chevrolet.id }),
        path: '/oportunidades/chevrolet',
      },
      {
        name: 'Nissan',
        onClick: () => this.setState({ activeBrand: brands.nissan.id }),
        path: '/oportunidades/nissan',
      },
      {
        name: 'Volkswagen',
        onClick: () => this.setState({ activeBrand: brands.volkswagen.id }),
        path: '/oportunidades/volkswagen',
      },
      {
        name: 'BAIC',
        onClick: () => this.setState({ activeBrand: brands.baic.id }),
        path: '/oportunidades/baic',
      }
    ];

    function getActiveBrandIndex(activeBrand) {
      switch (activeBrand) {
        case 1:
          return 3;
        case 2:
          return 1;
        case 4:
          return 2;
        case 6:
          return 4;
        default:
          return undefined;
      }
    }

    return (
      <div className="promotions">
        <Helmet>
          <title>Oportunidades 0km</title>
          <meta
            name="description"
            content="Territorio Yacopini - Vehículos con precio especial - Oportunidades"
          />
          <meta
            name="keywords"
            content="Territorio, Yacopini, Volkswagen, Chevrolet, Nissan, Ducati, vehiculos, autos, compra, 0km, planes de ahorro, autoahorro"
          />
        </Helmet>

        <DesktopHeader>
          <Header active={2} />
        </DesktopHeader>
        <MobileHeader>
          <MobileTabs title="Oportunidades por marca" tabs={brandSections} active={getActiveBrandIndex(activeBrand)} />

          <MobileNavigation active={2} />
        </MobileHeader>
        <Hero type="color" color="orange">
          <div className="col col-six col-m-twelve col-sd-nine">
            <h1>Oportunidades</h1>
            <p className="p-b">
              En Territorio Yacopini tenemos {offerAmount} unidades con
              increibles descuentos.¡No te quedes sin tu vehículo 0Km al mejor
              precio!
            </p>
            <p>
              <Button
                text="Explorar oportunidades"
                onClick={() =>
                  this.promotionsElement?.scrollIntoView({ behavior: 'smooth' })
                }
              />
              <Button
                text="Contactar un asesor"
                onClick={() =>
                  this.contact?.scrollIntoView({ behavior: 'smooth' })
                }
              />
            </p>
          </div>
        </Hero>

        <div
          className="section section-relative"
          ref={section => {
            this.promotionsElement = section;
          }}
        >
          <DesktopHeader>
            <StickyNav big sections={brandSections} active={getActiveBrandIndex(activeBrand)} />
          </DesktopHeader>

          {(activeBrand === null || activeBrand === brands.chevrolet.id) && (
            <React.Fragment>
              <div className="container">
                <div className="promotions__category-title">
                  <div className="col col-eight col-m-six">
                    <h5>Chevrolet ({gmOffers.length})</h5>
                  </div>
                  <div className="col col-four col-right col-m-six">
                    <Arrowlink
                      text="Ver todos"
                      small
                      onClick={this.seeAllBrands}
                      disabled={activeBrand === null}
                    />
                  </div>
                </div>
              </div>
              <div className="container">
                {gmOffersGroups.map((group, i) => {
                  const family = this.getFamily(2, group);

                  if (!family) {
                    return null;
                  }

                  return (
                    <div className="col col-four col-m-twelve" key={i}>
                      <OfferGroupCard
                        family={family}
                        offers={group}
                        onClick={() => this.showUnits(family, group)}
                      />
                    </div>
                  );
                })}
              </div>
            </React.Fragment>
          )}

          {(activeBrand === null || activeBrand === brands.nissan.id) && (
            <React.Fragment>
              <div className="container ">
                <div className="promotions__category-title">
                  <div className="col col-eight col-m-six">
                    <h5>Nissan ({nissanOffers.length})</h5>
                  </div>
                  <div className="col col-four col-right col-m-six">
                    <Arrowlink
                      text="Ver todos"
                      small
                      onClick={this.seeAllBrands}
                      disabled={activeBrand === null}
                    />
                  </div>
                </div>
              </div>
              <div className="container">
                {nissanOffersGroups.map((group, i) => {
                  const family = this.getFamily(4, group);

                  if (!family) {
                    return null;
                  }

                  return (
                    <div className="col col-four col-m-twelve" key={i}>
                      <OfferGroupCard
                        family={family}
                        offers={group}
                        onClick={() => this.showUnits(family, group)}
                      />
                    </div>
                  );
                })}
              </div>
            </React.Fragment>
          )}

          {(activeBrand === null || activeBrand === brands.volkswagen.id) && (
            <React.Fragment>
              <div className="container">
                <Desktop>
                  <br />
                </Desktop>
                <div className="promotions__category-title">
                  <div className="col col-eight col-m-six">
                    <h5>Volkswagen ({vwOffers.length})</h5>
                  </div>
                  <div className="col col-four col-right col-m-six">
                    <Arrowlink
                      text="Ver todos"
                      small
                      onClick={this.seeAllBrands}
                      disabled={activeBrand === null}
                    />
                  </div>
                </div>
              </div>
              <div className="container">
                {vwOffersGroups.map((group, i) => {
                  const family = this.getFamily(1, group);

                  if (!family) {
                    return null;
                  }

                  return (
                    <div className="col col-four col-m-twelve" key={i}>
                      <OfferGroupCard
                        family={family}
                        offers={group}
                        onClick={() => this.showUnits(family, group)}
                      />
                    </div>
                  );
                })}
              </div>
            </React.Fragment>
          )}

          {(activeBrand === null || activeBrand === brands.baic.id) && (
            <React.Fragment>
              <div className="container">
                <Desktop>
                  <br />
                </Desktop>
                <div className="promotions__category-title">
                  <div className="col col-eight col-m-six">
                    <h5>BAIC ({baicOffers.length})</h5>
                  </div>
                  <div className="col col-four col-right col-m-six">
                    <Arrowlink
                      text="Ver todos"
                      small
                      onClick={this.seeAllBrands}
                      disabled={activeBrand === null}
                    />
                  </div>
                </div>
              </div>
              <div className="container">
                {baicOffersGroups.map((group, i) => {
                  const family = this.getFamily(brands.baic.id, group);

                  if (!family) {
                    return null;
                  }

                  return (
                    <div className="col col-four col-m-twelve" key={i}>
                      <OfferGroupCard
                        family={family}
                        offers={group}
                        onClick={() => this.showUnits(family, group)}
                      />
                    </div>
                  );
                })}
              </div>
            </React.Fragment>
          )}

          <div className="container">
            <div className="col col-twelve col-padded" />
            <div className="col col-twelve col-padded">
              <p className="p-disclamer">
                * Vehículos únicos con condiciones especiales sujetos a la
                revisión del cliente.
                <br />
                ** Fotos no contractuales. Las imágenes publicadas en este sitio
                son de carácter ilustrativo.
              </p>
            </div>
          </div>
        </div>
        <div
          className="section section-white section-big"
          ref={section => {
            this.contact = section;
          }}
        >
          <div className="container">
            <div className="col col-five col-m-twelve col-sd-six col-center">
              <h2 className="heading-adjust">
                ¿No encontraste el vehículo que buscabas?
              </h2>
              <p>
                Dejanos tus datos y te contactarémos tan pronto cómo tengamos
                las mejores ofertas de tu vehículo preferido.
              </p>
              <p>
                O acercate a nuestras oficinas y te brindaremos el mejor
                asesoramiento.
              </p>
            </div>
            <div className="col col-two col-m-twelve col-only-desktop-hd" />
            <div className="col col-five col-m-twelve">
              <div>
                <Textfield label="Nombre" half />
                <Textfield label="Apellido" half />
              </div>
              <div>
                <Textfield label="Teléfono" placeholder="Ej. 261 5 123 456" />
              </div>
              <div>
                <Textfield label="Email" />
              </div>
              <Button text="Enviar" variant="main" />
            </div>
          </div>
        </div>
        <Footer alt />
      </div>
    );
  }

  familiesLoaded() {
    return Object.keys(this.props.families).length === 4;
  }

  renderLoading() {
    return (
      <div className="promotions">
        <Desktop>
          <Header active={2} />
        </Desktop>
        <Loader />
      </div>
    );
  }

  getFamily(brandId, group) {
    const familyId = group[0].family_id;

    const families = this.props.families[brandId].filter(
      family => family.id === familyId
    );

    return families[0];
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.showFamily && !this.state.showFamily) {
      window.scrollTo(0, this.state.scrollY);
    }
  }

  showUnits(family, units) {
    this.setState({ showFamily: family, units, scrollY: window.scrollY });
  }

  hideUnits() {
    this.setState({ showFamily: null, units: null });
  }
}

const mapStateToProps = state => {
  return {
    vwOffers: state.offer.byBrand[brands.volkswagen.id].best,
    gmOffers: state.offer.byBrand[brands.chevrolet.id].best,
    nissanOffers: state.offer.byBrand[brands.nissan.id].best,
    baicOffers: state.offer.byBrand[brands.baic.id].best,
    vwOffersGroups: state.offer.byBrand[brands.volkswagen.id].grouped,
    gmOffersGroups: state.offer.byBrand[brands.chevrolet.id].grouped,
    nissanOffersGroups: state.offer.byBrand[brands.nissan.id].grouped,
    baicOffersGroups: state.offer.byBrand[brands.baic.id].grouped,
    families: state.families.grouped,
    allFamilies: state.families.all,
    theme: state.brand.theme
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchFamily: brandId => dispatch(familiesActions.fetchAll({ brandId }))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Promotions);
