import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Responsive from 'react-responsive';

import MobileTabs from 'components/molecules/mobileTabs/index.jsx';
import Header from 'components/organisms/header/index.jsx';
import MobileNavigation from 'components/organisms/mobileNavigation/index.jsx';
import Footer from 'components/organisms/footer/index.jsx';
import Tabs from 'components/molecules/tabs/index.jsx';

import './News.scss';

const Desktop = props => <Responsive {...props} minWidth={1152} />;
const Mobile = props => <Responsive {...props} maxWidth={1151} />;
const DesktopHeader = props => <Responsive {...props} minWidth={1024} />;
const MobileHeader = props => <Responsive {...props} maxWidth={1023} />;

function NewsItem({ news }) {
  return (
    <div className="col col-five col-m-twelve col-not-padded">
      <div className='news__item'>
        <h5>{news.type_strong}. {news.brand_string}.</h5>
        <h3>{news.title}</h3>
        <p><strong>{news.display_date}</strong></p>
        <div dangerouslySetInnerHTML={{ __html: news.excerpt }} />
        <Link to={news.slug_url}>Ver más</Link>
      </div>
    </div>
  );
}

class News extends React.Component {
  state = {
    activeTab: undefined
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleTabClick = (tab) => {
    this.setState({ activeTab: tab.id });
  }

  render() {
    const { activeTab } = this.state;

    const tabs = [
      {
        name: 'Todas',
      },
      {
        name: 'Volkswagen',
        id: 1,
      },
      {
        name: 'Chevrolet',
        id: 2,
      },
      {
        name: 'Nissan',
        id: 4,
      },
      {
        name: 'Territorio Yacopini',
        id: 5,
      },
    ];

    if (!this.props.parameters) {
      return null;
    }

    const filteredNews = activeTab ? this.props.parameters.news.filter(news => news.brand_id === activeTab) : this.props.parameters.news;
    const activeTabIndex = tabs.findIndex(tab => tab.id === activeTab);

    return (
      <div className="news">    
        <Helmet>
          <title>Territorio Yacopini</title>
          <meta
            name="description"
            content="Territorio Yacopini - Noticias"
          />
          <meta
            name="keywords"
            content="Territorio, Yacopini, Volkswagen, Chevrolet, Nissan, Ducati, vehiculos, autos, compra, 0km, planes de ahorro, autoahorro"
          />
        </Helmet>
        <DesktopHeader>
          <Header active={0} />
        </DesktopHeader>
        
        <MobileHeader>
          <MobileNavigation active={0} />
        </MobileHeader>
        <Mobile>
          <MobileTabs title="Filtrar por marcas" tabs={tabs} active={activeTabIndex} onClick={this.handleTabClick} />
        </Mobile>
          <div className=" section section-center section-white section-relative news__hero">
            <div className="container">
              <div className='col col-twelve'>
                <br />
                <h1>Noticias</h1>
                <Desktop>
                  <Tabs
                    title="Ver noticias de:"
                    tabs={tabs}
                    active={activeTabIndex}
                    onClick={this.handleTabClick}
                  />
                </Desktop>
              </div>
            </div>
          </div>  
          <div className="section section-center section-white section-relative news__list">
            {filteredNews.map((news, i) => i % 2 === 0 ? (
              <div className="container">
                <NewsItem news={filteredNews[i]} />
                <div className="col col-one col-not-mobile"></div>
                {filteredNews[i+1] && <NewsItem news={filteredNews[i+1]} />}
              </div>
            ) : null)}
          </div>
          {/*
          <div className=" section section-center section-white news__pages">
            <div className="container">
              <div className='col col-twelve'>
                <div className='pages'>
                  <Desktop>
                  <a className='pages__prev' href="#">Anterior</a>
                  <a className='pages__page active' href="#">1</a>
                  <a className='pages__page' href="#">2</a>
                  <a className='pages__next' href="#">Siguiente</a>
                  </Desktop>
                  <Mobile>
                    <a className='pages__prev' href="#">Anterior</a>
                    <a className='pages__next' href="#">Siguiente</a>
                    <br/>
                    <a className='pages__page active' href="#">1</a>
                    <a className='pages__page' href="#">2</a>
                  </Mobile>
                </div>
              </div>
            </div>
          </div>
           */}  
        <Footer alt />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    parameters: state.parameter.all,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(News);
