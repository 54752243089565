import React from 'react';
import ProgressBar from 'components/molecules/progressBar/index.jsx';
import { parseRoute } from 'config/routes';

const FlowProgressBar = ({ step, operation_code }) => (
  <ProgressBar
    steps={[
      {
        id: 1,
        name: 'Modo de pago',
        link: parseRoute('dealership_payment_selection', { operation_code })
      },
      {
        id: 2,
        name: 'Datos Personales',
        link: parseRoute('dealership_personal_information', { operation_code })
      },
      {
        id: 3,
        name: 'Revisa y confirma',
        link: parseRoute('dealership_review', { operation_code })
      },
      {
        id: 4,
        name: 'Pago de Seña',
        link: parseRoute('dealership_payment', { operation_code })
      }
    ]}
    active={step}
  />
);

export default FlowProgressBar;
