import React from 'react';
import PropTypes from 'prop-types';
import './ToggleButton.scss';

export default class ToggleButton extends React.Component {
  constructor(props) {
    super(props);

    this.handleOnClick = this.handleOnClick.bind(this);
  }

  handleOnClick(e) {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  }

  render() {
    const { on, title, small, disabled } = this.props;

    let className =
      'toggle-button ' +
      (on ? ' toggle-button-on' : '') +
      (disabled ? ' toggle-button-disabled' : '') +
      (small ? ' toggle-button-small' : '');

    var titleElement;
    if (title) {
      titleElement = <h5 className="toggle-button__title">{title}</h5>;
    }

    return (
      <div className={className}>
        {titleElement}
        <button
          className="toggle-button__button"
          onClick={this.handleOnClick}
          type="button"
          disabled={disabled}
        />
      </div>
    );
  }
}

ToggleButton.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.string
};
