const tokenFromBrand = {
  1: 'gcu1tr29S7Tf7DPkiJR3KoRy',
  2: 'qfLbMiCFSJvjiDeDcqCoTxtx',
  4: 'ZVwLKwS7hugny35TxvSewV9o',
  6: 'CWGCzt7T6b7wzCrHNec4sCKS'
}

const LinkingPos = ({ amount, operation }) => {
  const transaction = operation.purchase.transaction;
  const token = tokenFromBrand[operation.brand_id];

  if (!token) {
    return <div>Imposible procesar el pago en este momento.</div>;
  }

  return (
    <iframe
      title="Pago de reserva"
      src={`https://linkingpos.com/external_payments/new?access_token=${token}&import=${amount}&client_ref=${transaction.code}`}
      style={{ width: "100%", height: "820px", overflowY: "hidden" }}
      scrolling="no"
      frameBorder="0"
    ></iframe>
  );
};

export default LinkingPos;
