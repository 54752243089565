import { slugify } from "helpers/url";

export default class Story {
  constructor(data = {}) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        this[key] = data[key];
      }
    }
  }

  get slug_url() {
    const title = slugify(this.title);
    return `/historias/${this.id}/${title}`;
  }
}
