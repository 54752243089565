import { getBrandName } from 'config/brands';

export default class Offer {
  constructor(data = {}) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        this[key] = data[key];
      }
    }
  }

  set model(model) {
    this.name = model;
  }

  set price(price) {
    this._price = this.parsePrice(price);
  }

  set discount(discount) {
    this._discount = this.parsePrice(discount);
  }

  set special_discount(discount) {
    this._special_discount = this.parsePrice(discount);
  }

  set doors(doors) {
    this._doors = doors;
  }

  set color(color) {
    this._color = color;
  }

  set location(location) {
    this._location = location;
  }

  get brand() {
    return getBrandName(this.brand_id);
  }

  get discount() {
    return this._discount;
  }

  get special_discount() {
    return this._special_discount;
  }

  get price() {
    if (this.model_year) {
      return this._price;
    }

    return this._price + this.discount_amount;
  }

  get price_discount() {
    if (this.model_year) {
      return this.price - this.discount_amount;
    }

    return this._price;
  }

  get doors() {
    return this._doors ? this._doors : null;
  }

  get color() {
    return this._color ? this._color : null;
  }

  get location() {
    return this._location ? this._location : null;
  }

  get discount_type() {
    return 'Descuento especial';
  }

  get discount_amount() {
    return this.special_discount;
  }

  parsePrice(price = '') {
    if (typeof price !== 'string') {
      return price;
    }

    return parseFloat(price);
  }
}
