import React from 'react';
import './Textarea.scss';

export default class Textarea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldState: 'pristine'
    };
    this.setFieldState = this.setFieldState.bind(this);
  }

  setFieldState(newState) {
    this.setState(state => ({
      fieldState: newState
    }));
  }
  render() {
    const {
      label,
      placeholder,
      disabled,
      error,
      errorMessage,
      helpText,
      required,
      half,
      third,
      twothird,
      fourth,
      ...etc
    } = this.props;

    let className =
      'textarea' +
      (error ? ' textarea-error' : '') +
      (label ? ' textarea-label' : '') +
      (half ? ' textarea-half' : '') +
      (third ? ' textarea-third' : '') +
      (twothird ? ' textarea-two-third' : '') +
      (fourth ? ' textarea-fourth' : '') +
      (required ? ' textarea-required' : '') +
      (this.state.fieldState ? ' textarea-' + this.state.fieldState : '') +
      (disabled ? ' textarea-disabled' : '');

    var labelElement;
    if (label) {
      labelElement = <div className="textarea__label">{label}</div>;
    }

    var helpElement;
    if (helpText) {
      helpElement = <div className="textarea__help-text">{helpText}</div>;
    }

    var messageElement;
    if (errorMessage) {
      messageElement = (
        <div className="textarea__error-message">{errorMessage}</div>
      );
    }

    return (
      <div className={className}>
        {labelElement}
        <textarea
          placeholder={placeholder}
          className="textarea__input"
          id={label}
          cols="30"
          rows="10"
          onFocus={e => this.setFieldState('focus', e)}
          onBlur={e => this.setFieldState('pristine', e)}
          required={required}
          {...etc}
        />

        {messageElement}
        {helpElement}
      </div>
    );
  }
}
