const MAX_LENGTH = 500;

export function getExcerpt(news) {
    if (news.length < MAX_LENGTH) {
        return news.content;
    }

    const excerpt = news.match(/<p>(.*?)<\/p>/);

    if (excerpt) {
        return `${excerpt[0]}`;
    }

    return `${news.content.substring(0, MAX_LENGTH)}...`;
}