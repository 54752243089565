import React from 'react';
import './FlowNavigation.scss';
import { hide } from 'helpers/chat';

const FlowNavigation = ({ type, progressBar, children }) => {
  hide();

  let className =
    'flow-navigation' +
    (type ? ' flow-navigation-' + type : '') +
    (progressBar ? ' flow-navigation-progress-bar' : '');

  return (
    <div className={className}>
      {progressBar}
      <div className="flow-navigation__content">{children}</div>
    </div>
  );
}

export default FlowNavigation;
