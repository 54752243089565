import React from 'react';
import { Link } from 'react-router-dom';
import './Tabs.scss';

const Tabs = ({ active, tabs, title, onClick }) => {
  const tabList = tabs.map((tab, i) => (
    <div
      key={i}
      className={
        'tabs__section' + (active === i ? ' tabs__section-active' : '')
      }
      onClick={() => {
        if (onClick) {
          onClick(tab, i);
        }

        if (tab.onClick) {
          tab.onClick(tab, i);
        }
      }}
    >
      {tab.link ? <Link to={tab.link}>{tab.name}</Link> : <span className="tabs__link">{tab.name}</span>}
    </div>
  ));

  return (
    <div className="tabs">
      {title !== undefined && title !== '' && (
        <div className="tabs__title">
          <h5 className="fluid">{title}</h5>
        </div>
      )}
      <div className="tabs__container">{tabList}</div>
    </div>
  );
};

export default Tabs;
