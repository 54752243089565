import React, { useEffect } from 'react';
import Responsive from 'react-responsive';
import ModalHeader from 'components/organisms/modalHeader/index.jsx';
import MobileHeader from 'components/organisms/mobileHeader/index.jsx';
import UnitsCard from 'components/molecules/unitsCard/index.jsx';
import { Helmet } from 'react-helmet';
import './Units.scss';

const Desktop = props => <Responsive {...props} minWidth={1152} />;
const Mobile = props => <Responsive {...props} maxWidth={1151} />;

function Units(props) {
  const { family, subfamily, vehicles, onClose, theme, brand, offer } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [family, subfamily]);

  return (
    <div className="units">
      <Helmet>
        {!offer && (
          <title>Territorio Yacopini - {family?.name} 0km en stock</title>
        )}
        {offer && (
          <title>Territorio Yacopini - oportunidades {family?.name} 0km</title>
        )}
        <meta
          name="description"
          content={`Territorio Yacopini - {family?.name} 0km en stock compra directa.`}
        />
      </Helmet>
      <Desktop>
        <ModalHeader
          title={
            subfamily
              ? subfamily?.name.toLowerCase()
              : family?.name.toLowerCase()
          }
          type="close"
          units
          onClose={onClose}
          unitsImage={family.image}
          model={`${vehicles.length} ${
            vehicles.length > 1 ? 'unidades' : 'unidad'
          }: ${subfamily ? family.name.toLowerCase() : brand}`}
        />
      </Desktop>
      <Mobile>
        <MobileHeader
          title={`Unidades: ${family?.name.toLowerCase()} ${subfamily?.name.toLowerCase()}`}
          type="close"
          onClose={onClose}
        />
      </Mobile>
      <Desktop>
        <div className="section section-modal" />
        <div className="section section-small">
          <div className="container">
            {vehicles.map(vehicle => (
              <div className="col col-six col-m-twelve" key={vehicle.id}>
                <UnitsCard vehicle={vehicle} theme={theme} family={family} />
              </div>
            ))}
          </div>
        </div>
        <div className="section section-small ">
          <div className="container">
            <div className="col col-twelve">
              <p className="p-xs">
                *Los precios no incluyen gastos de patentamiento ni entrega.
              </p>
            </div>
          </div>
        </div>
        {/* <div className="section units-characteristics ">
          <div className="container">
            <div className="col col-twelve">
              <h4 className="units-characteristics__title">
                Ficha técnica: -brandName- {family.name.toLowerCase()}{' '}
                {subfamily.name.toLowerCase()}
              </h4>
              <div className="units-characteristics__list">
                <div className="units-characteristics__list__item">
                  Computadora de a bordo
                </div>
                <div className="units-characteristics__list__item">
                  Faros antiniebla traseros
                </div>
                <div className="units-characteristics__list__item">
                  Volante de cuero
                </div>
                <div className="units-characteristics__list__item">
                  Airbags frontales para conductor y acompañante y abs
                </div>
                <div className="units-characteristics__list__item">
                  Control de estabilidad y de tracción
                </div>
                <div className="units-characteristics__list__item">
                  Computadora de a bordo
                </div>
                <div className="units-characteristics__list__item">
                  Faros antiniebla traseros
                </div>
                <div className="units-characteristics__list__item">
                  Volante de cuero
                </div>
                <div className="units-characteristics__list__item">
                  Airbags frontales para conductor y acompañante y abs
                </div>
                <div className="units-characteristics__list__item">
                  Control de estabilidad y de tracción
                </div>
              </div>
              <Arrowlink text="Descargar ficha técnica" small />
            </div>
          </div>
        </div>*/}
      </Desktop>

      <Mobile>
        <div className="section section-modal section-white section-center">
          <div className="container">
            <div className="col col-twelve">
              <h5>Elegí la unidad para reservar:</h5>
              <h2 className="mobile__units__title">{`Unidades: ${family.name.toLowerCase()} ${
                subfamily ? subfamily.name.toLowerCase() : ''
              }`}</h2>
            </div>
          </div>
        </div>
        <div className="section section-small section-white">
          <div className="container">
            {vehicles.map(vehicle => (
              <div
                className="col col-six col-m-twelve col-not-padded"
                key={vehicle.id}
              >
                <UnitsCard vehicle={vehicle} theme={theme} family={family} />
              </div>
            ))}
          </div>
        </div>
        <div className="section section-white ">
          <div className="container">
            <div className="col col-twelve">
              <p className="p-xs">
                *Los precios no incluyen gastos de patentamiento ni entrega.
              </p>
            </div>
          </div>
        </div>

        {/*<div className="section section-white units-characteristics ">
          <div className="container">
            <div className="col col-twelve">
              <h4 className="units-characteristics__title">
                Ficha técnica: {family.name.toLowerCase()}{' '}
                {subfamily.name.toLowerCase()}
              </h4>
              <div className="units-characteristics__list">
                <div className="units-characteristics__list__item">
                  Computadora de a bordo
                </div>
                <div className="units-characteristics__list__item">
                  Faros antiniebla traseros
                </div>
                <div className="units-characteristics__list__item">
                  Volante de cuero
                </div>
                <div className="units-characteristics__list__item">
                  Airbags frontales para conductor y acompañante y abs
                </div>
                <div className="units-characteristics__list__item">
                  Control de estabilidad y de tracción
                </div>
                <div className="units-characteristics__list__item">
                  Computadora de a bordo
                </div>
                <div className="units-characteristics__list__item">
                  Faros antiniebla traseros
                </div>
                <div className="units-characteristics__list__item">
                  Volante de cuero
                </div>
                <div className="units-characteristics__list__item">
                  Airbags frontales para conductor y acompañante y abs
                </div>
                <div className="units-characteristics__list__item">
                  Control de estabilidad y de tracción
                </div>
              </div>
              <Arrowlink text="Descargar ficha técnica" small />
            </div>
          </div>
        </div>*/}

        <div className="section section-big">
          <div className="container">
            <div className="col col-twelve">
              <h4>Reservar Online es fácil y rápido</h4>
              <p className="p-s">
                Solo pagas una seña con tu tarjeta de crédito. Si te
                arrepientes, dentro de las 48 horas, te devolvemos el 100% de la
                seña.
              </p>
            </div>
          </div>
        </div>
      </Mobile>
    </div>
  );
};

export default Units;