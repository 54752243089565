import { combineReducers } from 'redux';
import brandReducer from './brand';
import creditsReducer from './credits';
import dealershipReducer from './dealership';
import familiesReducer from './families';
import familyReducer from './family';
import offerReducer from './offer';
import parameterReducer from './parameter';
import promotionCodeReducer from './promotionCode';
import savingPlanReducer from './savingPlan';
import serviceReducer from './service';
import stockReducer from './stock';
import subfamilyReducer from './subfamily';

const rootReducer = combineReducers({
  brand: brandReducer,
  credits: creditsReducer,
  dealership: dealershipReducer,
  families: familiesReducer,
  family: familyReducer,
  offer: offerReducer,
  parameter: parameterReducer,
  promotionCode: promotionCodeReducer,
  savingPlan: savingPlanReducer,
  serviceCategory: serviceReducer,
  stock: stockReducer,
  subfamily: subfamilyReducer
});

export default rootReducer;
