import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Hero from 'components/organisms/hero/index.jsx';

import FlowNavigation from 'components/molecules/flowNavigation/index.jsx';
import FlowHeader from 'components/molecules/flowHeader/index.jsx';
import Textfield from 'components/molecules/textfield/index.jsx';
import Button from 'components/atoms/button/index.jsx';
import Loader from 'components/atoms/loader';

import brands from 'config/brands';
import stockActions from 'actions/stock';
import * as dealershipActions from 'actions/dealership';

import { parseRoute } from 'config/routes';

import '../DigitalDealership.scss';

class StartEmail extends React.Component {
  state = {
    email: ''
  };

  componentDidMount() {
    const { operation_code } = this.props.match.params;

    if (operation_code) {
      this.props.reset();

      this.props.start({
        code: operation_code
      });
    }

    if (!this.props.vehicle) {
      const { brand, id } = this.props.match.params;

      this.props.fetch({
        brandId: brands[brand].id,
        id
      });
    }
  }

  reset() {
    this.props.reset();
  }

  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  requestStart(e) {
    e.preventDefault();

    const { vehicle, promotion } = this.props;
    const { brand, family, family_id } = this.props.match.params;

    this.props.start({
      email: this.state.email,
      stock_id: vehicle.id,
      model: vehicle.name,
      brand_id: brands[brand].id,
      chassis: vehicle.getChassis(),
      family,
      family_id,
      promotion_code: promotion ? promotion.code : '',
      fullprice: vehicle.getPromotionPrice(promotion),
      special_discount: vehicle.discount_amount
    });
  }

  render() {
    const {
      vehicle,
      stockError,
      startError,
      operation,
      requesting
    } = this.props;
    const { brand, operation_code } = this.props.match.params;

    if (vehicle === undefined) {
      return this.renderLoading();
    }

    if (vehicle === null || stockError) {
      return this.renderError('El vehículo seleccionado no está disponible.');
    }

    if (startError) {
      return this.renderError(
        <div>
          <p>Error iniciando la compra.</p>
          <p>
            <Button
              text="Reintentar"
              type="button"
              onClick={this.reset.bind(this)}
            />
          </p>
        </div>
      );
    }

    if (operation) {
      return this.renderSuccess();
    }

    return (
      <div className="digital-dealership digital-dealership__start">
        <Helmet>
          <title>Concesionario Digital - Reservar {vehicle.name}</title>
        </Helmet>
        <FlowHeader center />
        <form onSubmit={this.requestStart.bind(this)}>
          <Hero
            type="background"
            big
            smallMobile
            imageAlt="Concesionario Digital"
          >
            <div className="col col-three col-not-mobile " />
            <div className="col col-six col-m-twelve col-center ">
              <h2>Concesionario Digital</h2>
              {!operation_code && (
                <>
                  <p className="p p-b">
                    Reservá online este{' '}
                    <strong>
                      {brands[brand].name} {vehicle.name}
                    </strong>{' '}
                    online en solo unos minutos. Para empezar, ingresá tu email
                    y te guiaremos en el proceso.
                  </p>
                  <br />
                  <Textfield
                    type="email"
                    placeholder="Ej: tunombre@gmail.com"
                    twothird
                    onChange={this.handleEmailChange.bind(this)}
                    value={this.state.email}
                    required
                  />
                </>
              )}
              {requesting && <Loader />}
            </div>
          </Hero>

          <FlowNavigation>
            <Button
              text="Comenzar"
              variant="main"
              type="submit"
              disabled={!this.state.email || requesting}
            />
          </FlowNavigation>
        </form>
      </div>
    );
  }

  renderLoading() {
    return (
      <div className="digital-dealership digital-dealership__start">
        <FlowHeader center />
        <Loader />
      </div>
    );
  }

  renderError(message) {
    return (
      <div className="digital-dealership digital-dealership__start">
        <FlowHeader center />
        <Hero
          type="background"
          big
          smallMobile
          imageAlt="Concesionario Digital"
        >
          <div className="col col-three col-not-mobile " />
          <div className="col col-six col-m-twelve col-center ">
            <h2>Concesionario Digital</h2>
            <div className="p p-b">{message}</div>
            <br />
            <Link to="/">Volver</Link>
          </div>
        </Hero>
      </div>
    );
  }

  renderSuccess() {
    const { operation } = this.props;
    const { operation_code } = this.props.match.params;

    return (
      <div className="digital-dealership digital-dealership__start">
        <FlowHeader center />
        <Hero
          type="background"
          big
          smallMobile
          imageAlt="Concesionario Digital"
        >
          <div className="col col-three col-not-mobile " />
          <div className="col col-six col-m-twelve col-center ">
            <h2>Concesionario Digital</h2>
            {!operation_code && (
              <>
                <p className="p p-b">
                  Todo listo para continuar. <br />
                  Te hemos enviado un email para que puedas retomar la operación
                  más tarde.
                </p>
                <p className="p p-b">
                  El código de tu operación es <strong>{operation.code}</strong>
                  .
                </p>
              </>
            )}
            {operation_code && (
              <>
                <p className="p p-b">
                  Todo listo para continuar con la reserva de tu próximo{' '}
                  {operation.family}
                </p>
                <p className="p p-b">
                  El código de tu operación es <strong>{operation.code}</strong>
                  .
                </p>
              </>
            )}
          </div>
        </Hero>

        <FlowNavigation>
          <Link
            to={parseRoute('dealership_payment_selection', {
              operation_code: operation.code
            })}
          >
            <Button text="Siguiente" variant="main" type="button" />
          </Link>
        </FlowNavigation>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    vehicle: state.stock.selected,
    requesting: state.dealership.requesting,
    stockError: state.stock.error,
    startError: state.dealership.error,
    operation: state.dealership.operation,
    promotion: state.promotionCode.promotion
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetch: ({ brandId, id }) => {
      const query = brandId === 4 || brandId === 6 ? false : true;

      dispatch(stockActions.fetchOne({ id, brandId, query }));
    },
    start: data => dispatch(dealershipActions.start(data)),
    reset: () => dispatch(dealershipActions.reset())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StartEmail);
