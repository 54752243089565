export function hide() {
  const chat = document.querySelector('body > div:not(#root)');

  if (chat) {
    chat.style.display = 'none';
  }
}

export function show() {
  const chat = document.querySelector('body > div:not(#root)');

  if (chat) {
    chat.style.display = 'block';
  }
}
