import React from 'react';
import { connect } from 'react-redux';
import './Salesman.scss';
import whatsappImg from 'resources/img/whatsapp-header.svg';
import zoomImg from 'resources/img/zoom.svg';

export default class Salesman extends React.Component {
  componentDidMount() {}

  render() {
    const { name, image, initials, whatsapp, zoom, whatsAppText } = this.props;
    let className = 'salesman ' + (image ? ' salesman-withImage' : '');
    var imageElement;
    if (image) {
      imageElement = (
        <div className="salesman__avatar__image">
          <img src={image} alt={name} />
        </div>
      );
    }
    var waElement;
    if (whatsapp) {
      waElement = (
        <div className="salesman__whatsapp">
          <a
            target="_blank"
            rel="noreferrer noopener"
            href={`https://wa.me/${whatsapp}?text=${whatsAppText}`}
          >
            <img src={whatsappImg} alt="WhatsApp" />
          </a>
        </div>
      );
    }
    var zoomElement;
    if (zoom) {
      zoomElement = (
        <div className="salesman__zoom">
          <a target="_blank" rel="noreferrer noopener" href={zoom}>
            <img src={zoomImg} alt="Zoom" />
          </a>
        </div>
      );
    }
    var contactElement;
    if (zoom || whatsapp) {
      contactElement = (
        <div className="salesman__contact">
          {waElement}
          {zoomElement}
        </div>
      );
    }

    return (
      <div className={className}>
        <div className="salesman__avatar">
          {imageElement}
          <span className="salesman__avatar__initials">{initials}</span>
        </div>
        <div className="salesman__name">{name}</div>
        {contactElement}
      </div>
    );
  }
}
