import brands from 'config/brands';

const defaultState = {
  ...brands.volkswagen,
  active: brands.volkswagen.id,
  brands
};

export default function appReducer(state = defaultState, action) {
  switch (action.type) {
    case 'BRAND_SWITCH_TO_VOLKSWAGEN':
      return {
        ...state,
        active: defaultState.brands.volkswagen.id,
        name: defaultState.brands.volkswagen.name,
        theme: defaultState.brands.volkswagen.theme,
        savingsPlanName: defaultState.brands.volkswagen.savingsPlanName
      };

    case 'BRAND_SWITCH_TO_CHEVROLET':
      return {
        ...state,
        active: defaultState.brands.chevrolet.id,
        name: defaultState.brands.chevrolet.name,
        theme: defaultState.brands.chevrolet.theme,
        savingsPlanName: defaultState.brands.chevrolet.savingsPlanName
      };

    case 'BRAND_SWITCH_TO_NISSAN':
      return {
        ...state,
        active: defaultState.brands.nissan.id,
        name: defaultState.brands.nissan.name,
        theme: defaultState.brands.nissan.theme,
        savingsPlanName: defaultState.brands.nissan.savingsPlanName
      };

    case 'BRAND_SWITCH_TO_BAIC':
      return {
        ...state,
        active: defaultState.brands.baic.id,
        name: defaultState.brands.baic.name,
        theme: defaultState.brands.baic.theme,
        savingsPlanName: undefined
      };

    case 'BRAND_SWITCH_TO_BRAND_NAME':
      return {
        ...state,
        active: defaultState.brands[action.brandName].id,
        name: defaultState.brands[action.brandName].name,
        theme: defaultState.brands[action.brandName].theme,
        savingsPlanName: undefined
      };
    default:
      return state;
  }
}
