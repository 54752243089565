import Offer from 'models/offer';
import { applyFamilyImage } from 'helpers/vehicle';

const defaultState = {
  requesting: false,
  error: false,
  all: [],
  byBrand: {
    '1': { all: [], best: [], grouped: [] },
    '2': { all: [], best: [], grouped: [] },
    '4': { all: [], best: [], grouped: [] },
    '6': { all: [], best: [], grouped: [] },
  }
};

function offerReducer(state = defaultState, action) {
  switch (action.type) {
    case `FETCH_ALL_OFFER_SENT`:
      return { ...state, ...defaultState, requesting: true };

    case `FETCH_ALL_OFFER_FAILED`:
      return { ...state, requesting: false, error: true };

    case 'SET_VEHICLE_IMAGES':
      //console.log(state.all[0] instanceof Offer, state.all)
      if (state.all && state.all.length > 0) {
        return {
          ...state,
          all: applyFamilyImage(state.all, action.vehicles),
          byBrand: {
            '1': {
              all: applyFamilyImage(state.byBrand['1'].all, action.vehicles),
              best: state.byBrand['1'].best,
              grouped: state.byBrand['1'].grouped
            },
            '2': {
              all: applyFamilyImage(state.byBrand['2'].all, action.vehicles),
              best: state.byBrand['2'].best,
              grouped: state.byBrand['2'].grouped
            },
            '4': {
              all: applyFamilyImage(state.byBrand['4'].all, action.vehicles),
              best: state.byBrand['4'].best,
              grouped: state.byBrand['4'].grouped
            },
            '6': {
              all: applyFamilyImage(state.byBrand['6'].all, action.vehicles),
              best: state.byBrand['6'].best,
              grouped: state.byBrand['6'].grouped
            }
          },
          grouped: []
        };
      }

      return state;

    case `FETCH_ALL_OFFER_SUCCESS`:
      const offers = transformPayload(action.payload, action.brandId);

      return {
        ...state,
        all: state.all.concat(offers),
        byBrand: {
          ...state.byBrand,
          [action.brandId]: {
            all: offers,
            best: orderByDiscount(offers),
            grouped: groupByFamily(offers)
          }
        },
        requesting: false
      };

    default:
      return state;
  }
};

export default offerReducer;

function transformPayload(offers = [], brandId) {
  try {
    return offers.map(offer => new Offer({ ...offer, brand_id: brandId }));
  } catch (e) {
    console.error(e);

    return [];
  }
}

function orderByDiscount(offers) {
  return offers
    .reduce((offers, currentOffer) => {
      const repeatedOffer = offers.filter(offer =>
        areEquivalentOffers(offer, currentOffer)
      );

      if (repeatedOffer.length > 0) {
        return offers;
      }

      offers.push(currentOffer);

      return offers;
    }, [])
    .sort((a, b) => {
      return a.discount - b.discount;
    });
}

function areEquivalentOffers(offer1, offer2) {
  return (
    offer1.family_id === offer2.family_id &&
    offer1.subfamily_id === offer2.subfamily_id &&
    offer1.color === offer2.color
  );
}

function groupByFamily(offers) {
  const groups = {};

  offers.forEach(offer => {
    if (!groups[offer.family_id]) {
      groups[offer.family_id] = [];
    }

    groups[offer.family_id].push(offer);
  });

  return Object.keys(groups).map(familyId => groups[familyId]);
}
