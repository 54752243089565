import React from 'react';
import PropTypes from 'prop-types';
import chevronDown from 'resources/img/chevron-down.svg';
import { Link } from 'react-router-dom';
import './DropdownButton.scss';

export default class DropdownButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false
    };
  }

  handleClickOutside() {
    this.setState({
      dropdownOpen: false
    });
  }
  toggleList() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  render() {
    const {
      variant,
      color,
      type,
      text,
      small,
      fluid,
      disabled,
      spaced
    } = this.props;

    const { options } = this.props;
    const { dropdownOpen } = this.state;

    let className =
      'dropdown-button ' +
      (variant ? ' dropdown-button-' + variant : '') +
      (color ? ' dropdown-button-' + color : '') +
      (fluid ? ' dropdown-button-fluid' : '') +
      (disabled ? ' dropdown-button-disabled' : '') +
      (spaced ? ' dropdown-button-spaced' : '') +
      (small ? ' dropdown-button-small' : '');

    var arrowElement = (
      <div className="dropdown-button__arrow">
        <img src={chevronDown} alt="" />
      </div>
    );

    var dropdownElement = (
      <div className="dropdown-button__dropdown">
        {options.map(item => (
          <div className={'dropdown-button__dropdown__item'} key={item.title}>
            {item.link && item.external && (
              <a
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
                className="dropdown-button__dropdown__item__link"
              >
                {item.title}
              </a>
            )}

            {item.link && !item.external && (
              <Link
                to={item.link}
                className="dropdown-button__dropdown__item__link"
              >
                {item.title}
              </Link>
            )}
          </div>
        ))}
      </div>
    );

    return (
      <div
        className={
          'dropdown-button-container' +
          (dropdownOpen ? ' dropdown-button-container-open' : '')
        }
      >
        <button
          className={className}
          onClick={() => this.toggleList()}
          type={type || 'button'}
          disabled={disabled}
        >
          {text}
          {arrowElement}
        </button>
        {dropdownElement}
      </div>
    );
  }
}

DropdownButton.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.string
};
