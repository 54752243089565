import React, { useState } from 'react';
import Button from 'components/atoms/button/index.jsx';
import Textarea from 'components/molecules/textarea/index.jsx';
import Textfield from 'components/molecules/textfield/index.jsx';
import Loader from 'components/atoms/loader';
import Check from 'components/atoms/check/index.jsx';
import { handleSubmit, handleChange } from 'helpers/form';

export default ({ children, subject }) => {
  const [data, setData] = useState({
    name: '',
    lastname: '',
    telephone: '',
    email: '',
    message: '',
    subject
  });
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState(undefined);

  return (
    <div className="container">
      {success === true && (
        <div>
          <div className="col col-three col-m-twelve" />
          <div className="col col-six col-m-twelve col-center">
            <Check />
            <h2 className="heading-adjust">¡Recibimos tu mensaje!</h2>
            <p>
              Te estaremos contactando a la brevedad. Ante cualquier consulta
              podé<source /> llamarnos al +54 261 4439300. O visitarnos en San Martín
              Sur 600. Godoy Cruz, Mendoza.
            </p>
          </div>
        </div>
      )}
      {success === false && (
        <div>
          <div className="col col-three col-m-twelve" />
          <div className="col col-six col-m-twelve col-center">
            <h2 className="heading-adjust">¡Hubo un problema!</h2>
            <p>
              Intentalo de nuevo más tarde. Para consultas podés
              llamarnos al +54 261 4439300. O visitarnos en San Martín Sur 600.
              Godoy Cruz, Mendoza.
            </p>
          </div>
        </div>
      )}
      {sending && <Loader message="Un segundo, estamos enviando los datos." />}
      {success === undefined && !sending && (
        <React.Fragment>
          <div className="col col-five col-m-twelve col-center">{children}</div>
          <div className="col col-two col-m-twelve" />
          <div className="col col-five col-m-twelve">
            <form
              onSubmit={e => {
                e.preventDefault();

                handleSubmit({
                  data,
                  setSending,
                  setSuccess,
                  endpoint: '/api/v2/territorio/contact'
                });
              }}
            >
              <div>
                <Textfield
                  label="Nombre"
                  half
                  value={data.name}
                  required
                  name="name"
                  onChange={e => handleChange(data, setData, e)}
                />
                <Textfield
                  label="Apellido"
                  half
                  value={data.lastname}
                  required
                  name="lastname"
                  onChange={e => handleChange(data, setData, e)}
                />
              </div>
              <div>
                <Textfield
                  label="Teléfono"
                  placeholder="Ej. 261 5 123 456"
                  value={data.telephone}
                  required
                  name="telephone"
                  onChange={e => handleChange(data, setData, e)}
                />
              </div>
              <div>
                <Textfield
                  label="Email"
                  type="email"
                  value={data.email}
                  name="email"
                  onChange={e => handleChange(data, setData, e)}
                />
              </div>
              <div>
                <Textarea
                  label="Mensaje"
                  value={data.message}
                  name="message"
                  onChange={e => handleChange(data, setData, e)}
                />
              </div>
              <Button text="Enviar" variant="main" type="submit" />
            </form>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
