import fetch from 'isomorphic-fetch';
const API_URL = process.env.REACT_APP_API_URL;

export function handleChange(data, setData, e) {
  setData({ ...data, [e.target.name]: e.target.value });
}

export function handleSubmit({
  data,
  setSending,
  setSuccess,
  endpoint,
  callback
}) {
  setSending(true);

  return fetch(`${API_URL}${endpoint}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(() => setSuccess(true))
    .catch(() => {
      setSuccess(false);
    })
    .finally(() => {
      setSending(false);
      if (callback) {
        callback();
      }
    });
}
