import React from 'react';
import './Select.scss';

export default class select extends React.Component {
  constructor(props) {
    super(props);

    const preSelected = props.value
      ? props.list.filter(item => item.value === props.value)
      : [];
    const selectedItem = preSelected.length ? preSelected[0] : null;

    this.state = {
      listOpen: false,
      selectedItem
    };
  }

  toggleList() {
    this.setState(prevState => ({
      listOpen: !prevState.listOpen
    }));
  }

  toggleSelected(item) {
    this.setState({
      selectedItem: item
    });

    if (this.props.onChange) {
      this.props.onChange(item);
    }

    this.toggleList();
  }

  render() {
    const {
      label,
      name,
      placeholder,
      disabled,
      error,
      errorMessage,
      helpText,
      required,
      half,
      third,
      twothird,
      fourth,
      file,
      handleChange
    } = this.props;

    let className =
      'select' +
      (error ? ' select-error' : '') +
      (label ? ' select-label' : '') +
      (half ? ' select-half' : '') +
      (third ? ' select-third' : '') +
      (twothird ? ' select-two-third' : '') +
      (fourth ? ' select-fourth' : '') +
      (required ? ' select-required' : '') +
      (file ? ' select-file' : '') +
      (this.state.fieldState ? ' select-' + this.state.fieldState : '') +
      (disabled ? ' select-disabled' : '');

    const { list } = this.props;
    const { listOpen, selectedItem } = this.state;

    var labelElement;
    if (label) {
      labelElement = <div className="select__label">{label}</div>;
    }

    var helpElement;
    if (helpText) {
      helpElement = <div className="select__help-text">{helpText}</div>;
    }

    var messageElement;
    if (errorMessage) {
      messageElement = (
        <div className="select__error-message">{errorMessage}</div>
      );
    }
    var coreElement;
    coreElement = (
      <div
        className={'select__input ' + (listOpen ? 'select__input-open' : '')}
      >
        <div
          className="select__input__header"
          onClick={() => this.toggleList()}
        >
          <div className="select__input__header__arrow" />
          <div
            className={
              'select__input__header__title' +
              (selectedItem ? ' select__input__header__title-selected' : '')
            }
          >
            {!listOpen && !selectedItem && (
              <span>{placeholder || 'Seleciona una opción'}</span>
            )}
            {!listOpen && selectedItem && <span>{selectedItem.title}</span>}
            {listOpen && <span>{placeholder || 'Seleciona una opción'}</span>}
          </div>
        </div>
        {listOpen && (
          <div className="select__input__options">
            {list.map(item => (
              <div
                className={
                  'select__input__options__item' +
                  (selectedItem && selectedItem.value === item.value
                    ? ' select__input__options__item-selected'
                    : '')
                }
                key={item.value}
                onClick={() => this.toggleSelected(item)}
              >
                {item.title}
                {selectedItem && selectedItem.value === item.value && (
                  <div className="select__input__options__item__check" />
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    );

    return (
      <div className={className}>
        {labelElement}
        {coreElement}
        {messageElement}
        {helpElement}
        {handleChange && (
          <input
            type="hidden"
            name={name}
            value={selectedItem ? selectedItem.value : ''}
          />
        )}
      </div>
    );
  }
}
