import React, { useState } from 'react';
import Responsive from 'react-responsive';
import ModalHeader from 'components/organisms/modalHeader/index.jsx';
import MobileHeader from 'components/organisms/mobileHeader/index.jsx';
import MobileActions from 'components/organisms/mobileActions/index.jsx';
import Textfield from 'components/molecules/textfield/index.jsx';
import Button from 'components/atoms/button/index.jsx';
import Loader from 'components/atoms/loader';
import { handleSubmit, handleChange } from 'helpers/form';

import './ShowPrices.scss';

const copy =
  'Completando el formulario podrás consultar nuestros precios y disponibilidad, y reservar tu vehículo online para luego terminar la compra en nuestras oficinas.';
const endpoint = '/api/v2/territorio/price-request';

const Desktop = props => <Responsive {...props} minWidth={1152} />;
const Mobile = props => <Responsive {...props} maxWidth={1151} />;

export default props => {
  const { onClose, onSuccess, family } = props;

  const [data, setData] = useState({
    name: '',
    lastname: '',
    cellphone: '',
    email: '',
    vehicle: family.name
  });
  const [sending, setSending] = useState(false);

  const callback = onSuccess;
  const setSuccess = () => {};

  return (
    <div className="show-prices">
      <Desktop>
        <ModalHeader title="Consultar precios" type="back" onClose={onClose} />
      </Desktop>
      <Mobile>
        <MobileHeader title="Consultar precios" type="back" onClose={onClose} />
        <MobileActions
          variant="main"
          onClick={() =>
            handleSubmit({ data, setSending, setSuccess, endpoint, callback })
          }
          text="Enviar"
        />
      </Mobile>

      <Desktop>
        <div className="section section-big section-white show-prices__content">
          <div className="container">
            <div className="col col-three" />
            <div className="col col-six">
              {sending && <Loader />}
              {!sending && (
                <form
                  onSubmit={() =>
                    handleSubmit({
                      data,
                      setSending,
                      setSuccess,
                      endpoint,
                      callback
                    })
                  }
                >
                  <h2>Consulta nuestros precios</h2>
                  <p>{copy}</p>

                  <div>
                    <Textfield
                      label="Nombre"
                      half
                      required
                      name="name"
                      value={data.name}
                      onChange={e => handleChange(data, setData, e)}
                    />
                    <Textfield
                      label="Apellido"
                      half
                      required
                      name="lastname"
                      value={data.lastname}
                      onChange={e => handleChange(data, setData, e)}
                    />
                  </div>
                  <div>
                    <Textfield
                      label="Celular"
                      placeholder="Ej. 2615123456"
                      name="cellphone"
                      value={data.cellphone}
                      onChange={e => handleChange(data, setData, e)}
                    />
                  </div>
                  <div>
                    <Textfield
                      label="Email"
                      type="email"
                      required
                      name="email"
                      value={data.email}
                      onChange={e => handleChange(data, setData, e)}
                    />
                  </div>

                  <div className="show-prices__action">
                    <Button text="Enviar" type="submit" variant="main" />
                    <Button text="Cancelar" onClick={onClose} />
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </Desktop>

      <Mobile>
        <div className="section section-big show-prices__content">
          <div className="container ">
            <div className="col col-twelve">
              <h2>Consulta nuestros precios</h2>
              <p>{copy}</p>
            </div>
          </div>
        </div>
        <div className="section section-big section-white">
          <div className="container ">
            <div className="show-prices__fieldset">
              <div className="col col-twelve">
                {sending && <Loader />}
                {!sending && (
                  <React.Fragment>
                    <div>
                      <Textfield
                        label="Nombre"
                        required
                        name="name"
                        value={data.name}
                        onChange={e => handleChange(data, setData, e)}
                      />
                      <Textfield
                        label="Apellido"
                        required
                        name="lastname"
                        value={data.lastname}
                        onChange={e => handleChange(data, setData, e)}
                      />
                    </div>
                    <div>
                      <Textfield
                        label="Celular"
                        placeholder="Ej. 2615123456"
                        type="tel"
                        name="cellphone"
                        onChange={e => handleChange(data, setData, e)}
                        value={data.cellphone}
                      />
                    </div>
                    <div>
                      <Textfield
                        label="Email"
                        type="email"
                        required
                        name="email"
                        value={data.email}
                        onChange={e => handleChange(data, setData, e)}
                      />
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </Mobile>
    </div>
  );
};
