import { Link } from "react-router-dom";
import Responsive from "react-responsive";
import Button from "components/atoms/button/index.jsx";

const Desktop = props => <Responsive {...props} minWidth={1152} />;
const Mobile = props => <Responsive {...props} maxWidth={1151} />;

function News({ news }) {
  return (
    <div className="col col-five col-m-twelve">
      {news.url ? (
        <h3><a href={news.url} target="_blank" rel="noopener noreferrer">{news.title}</a></h3>
      ) : (
        <h3>{news.title}</h3>
      )}
      <div>
        <strong>{news.display_date}</strong> 
        <div dangerouslySetInnerHTML={{__html: news.excerpt }} />
      </div>
      <Link to={news.slug_url}>Ver más</Link>
    </div>
  );
}

function HomeNews({ news }) {
  if (!news || !news.length) {
    return null;
  }

  const displayNews = news.slice(0, 2);

  return (
    <>
      <Desktop>
        <div className="section section-white section-image  home__news">
          <div className="container">
            <div className="col col-twelve col-m-twelve">
              <h5>Noticias</h5>
            </div>
          </div>
          <div className="container">
              <News news={displayNews[0]} />
              <div className="col col-one col-not-mobile " />
              {displayNews[1] && <News news={displayNews[1]} />}
          </div>
          <div className="container">
            <div className="col col-twelve col-m-twelve">
              <br />
              <Link to="/noticias"><Button variant="main" text="Ver todas las noticias" /></Link>
            </div>
          </div>
        </div>
      </Desktop>
      <Mobile>
        <div className="section home__news__mobile">
          <div className="container">
            <div className="col col-twelve col-not-padded col-m-twelve">
              <h5>Noticias</h5>
            </div>
          </div>
          <div className="container">
            <News news={displayNews[0]} />
          </div>
          <div className="container">
            <div className="col col-not-padded col-twelve col-m-twelve">
              <Link to="/noticias"><Button variant="main" text="Ver todas las noticias" /></Link>
              <br />
              <br />
            </div>
          </div>
        </div>
      </Mobile>
    </>
  );
}

export default HomeNews;
