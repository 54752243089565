import cobaltHero from 'resources/img/gm/models/heros/cobalt_hero_background.jpg';
import montanaHero from 'resources/img/gm/models/heros/montana_hero_background.jpg';
import prismaHero from 'resources/img/gm/models/heros/prisma_hero_background.jpg';
import spinHero from 'resources/img/gm/models/heros/spin_hero_background.jpg';
import cruzeHero from 'resources/img/gm/models/heros/cruze_hero_background.jpg';
import onixHero from 'resources/img/gm/models/heros/onix_hero_background.jpg';
import prismaJoyHero from 'resources/img/gm/models/heros/prisma_joy_hero_background.jpg';
import trackerHero from 'resources/img/gm/models/heros/tracker_hero_background.jpg';
import equinoxHero from 'resources/img/gm/models/heros/equinox_hero_background.jpg';
import onixJoyHero from 'resources/img/gm/models/heros/onix_joy_hero_background.jpg';
import s10Hero from 'resources/img/gm/models/heros/s10_hero_background.jpg';
import trailblazerHero from 'resources/img/gm/models/heros/trailblazer_hero_background.jpg';

import amarokHero from 'resources/img/vw/models/heros/amarok_hero_background.jpg';
import beetleHero from 'resources/img/vw/models/heros/beetle_hero_background.jpg';
import ccHero from 'resources/img/vw/models/heros/cc_hero_background.jpg';
import crossfoxHero from 'resources/img/vw/models/heros/crossfox_hero_background.jpg';
import foxHero from 'resources/img/vw/models/heros/fox_hero_background.jpg';
import golHero from 'resources/img/vw/models/heros/gol_hero_background.jpg';
import golfHero from 'resources/img/vw/models/heros/golf_hero_background.jpg';
import golfVariantHero from 'resources/img/vw/models/heros/golf_variant_hero_background.jpg';
import nuevaAmarokHero from 'resources/img/vw/models/heros/nueva_amarok_hero_background.jpg';
import nuevoPoloHero from 'resources/img/vw/models/heros/nuevo_polo_hero_background.jpg';
import nuevoUpHero from 'resources/img/vw/models/heros/nuevo_up_hero_background.jpg';
import passatHero from 'resources/img/vw/models/heros/passat_hero_background.jpg';
import poloHero from 'resources/img/vw/models/heros/polo_hero_background.jpg';
import saveiroHero from 'resources/img/vw/models/heros/saveiro_hero_background.jpg';
import sciroccoHero from 'resources/img/vw/models/heros/scirocco_hero_background.jpg';
import sharanHero from 'resources/img/vw/models/heros/sharan_hero_background.jpg';
import suranHero from 'resources/img/vw/models/heros/suran_hero_background.jpg';
import tiguanHero from 'resources/img/vw/models/heros/tiguan_hero_background.jpg';
import touaregHero from 'resources/img/vw/models/heros/touareg_hero_background.jpg';
import upHero from 'resources/img/vw/models/heros/up_hero_background.jpg';
import ventoHero from 'resources/img/vw/models/heros/vento_hero_background.jpg';
import virtusHero from 'resources/img/vw/models/heros/virtus_hero_background.jpg';
import voyageHero from 'resources/img/vw/models/heros/voyage_hero_background.jpg';
import tcrossHero from 'resources/img/vw/models/heros/tcross_hero_background.jpg';

import nuevaFrontierHero from 'resources/img/nissan/models/heros/nueva_frontier_hero_background.jpg';
import frontierHero from 'resources/img/nissan/models/heros/frontier_hero_background.jpg';
import nuevoKicksHero from 'resources/img/nissan/models/heros/nueva_kicks_hero_background.jpg.jpg';
import kicksHero from 'resources/img/nissan/models/heros/kicks_hero_background.jpg';
import marchHero from 'resources/img/nissan/models/heros/march_hero_background.jpg';
import muranoHero from 'resources/img/nissan/models/heros/murano_hero_background.jpg';
import noteHero from 'resources/img/nissan/models/heros/note_hero_background.jpg';
import nuevoSentraHero from 'resources/img/nissan/models/heros/nuevo_sentra_hero_background.jpg';
import sentraHero from 'resources/img/nissan/models/heros/sentra_hero_background.jpg';
import nuevaVersaHero from 'resources/img/nissan/models/heros/nuevo_versa_hero_background.jpg';
import versaHero from 'resources/img/nissan/models/heros/versa_hero_background.jpg';
import xtrailHero from 'resources/img/nissan/models/heros/xtrail_hero_background.jpg';
import nuevaXtrailHero from 'resources/img/nissan/models/heros/nueva_xtrail_hero_background.jpg';

import x35 from 'resources/img/baic/x35.png';
import x55 from 'resources/img/baic/x55.png';
import bj40 from 'resources/img/baic/bj40.png';

export const applyFamilyImage = (vehicles, internalVehicles) => {
  return vehicles.map(vehicle => {
    if (!vehicle) {
      console.error(`Received undefined vehicle ${vehicle}`);
    }

    if (vehicle.image) {
      return vehicle;
    }
    for (const vehicle of internalVehicles) {
      vehicle.name = vehicle.name.replace(' T33', '').replace(' T32', '');
    }
    const internalVehicle = getInternalVehicle(vehicle, internalVehicles);

    if (!internalVehicle) {
      console.log(`Internal vehicle not found for ${vehicle?.name}`);
      return vehicle;
    }
    vehicle.image = internalVehicle.image;

    return vehicle;
  });
};

export const getInternalVehicle = (vehicle, internalVehicles) => {
  const name =
    typeof vehicle.family === 'string'
      ? vehicle.family.toLowerCase()
      : vehicle.name.toLowerCase();

  for (const internalVehicle of internalVehicles) {
    const candidateName = internalVehicle.name.toLowerCase();

    if (name === candidateName || candidateName.substring(0, name.length) === name) {
      return internalVehicle;
    }
  }

  for (const internalVehicle of internalVehicles) {
    const candidateName = internalVehicle.name.toLowerCase();

    if (candidateName.indexOf(name) >= 0 || name.indexOf(candidateName) >= 0) {
      return internalVehicle;
    }
  }

  for (const internalVehicle of internalVehicles) {
    const candidateName = internalVehicle.name.toLowerCase();

    if (candidateName.indexOf(name) >= 0 || name.indexOf(candidateName) >= 0) {
      return internalVehicle;
    }
  }
};

export const getHeroImage = family => {
  const familyName = family.name.toLowerCase().replace(/-/g, ' ');

  switch (familyName) {
    case 'cobalt':
      return { dark: true, hero: cobaltHero };
    case 'montana':
      return { dark: true, hero: montanaHero };
    case 'prisma':
      return { dark: false, hero: prismaHero };
    case 'spin':
      return { dark: true, hero: spinHero };
    case 'cruze':
      return { dark: false, hero: cruzeHero };
    case 'onix':
      return { dark: false, hero: onixHero };
    case 'prisma joy':
      return { dark: false, hero: prismaJoyHero };
    case 'tracker':
      return { dark: false, hero: trackerHero };
    case 'equinox':
      return { dark: true, hero: equinoxHero };
    case 'onix joy':
      return { dark: true, hero: onixJoyHero };
    case 's10':
      return { dark: true, hero: s10Hero };
    case 'trailblazer':
      return { dark: true, hero: trailblazerHero };

    case 'amarok':
      return { dark: true, hero: amarokHero };
    case 'beetle':
      return { dark: true, hero: beetleHero };
    case 'cc':
      return { dark: true, hero: ccHero };
    case 'crossfox':
      return { dark: true, hero: crossfoxHero };
    case 'fox':
      return { dark: false, hero: foxHero };
    case 'gol':
    case 'gol trend':
    case 'nuevo gol':
      return { dark: true, hero: golHero };
    case 'golf':
      return { dark: true, hero: golfHero };
    case 'golf variant':
      return { dark: true, hero: golfVariantHero };
    case 'nueva amarok':
      return { dark: true, hero: nuevaAmarokHero };
    case 'nuevo polo':
      return { dark: true, hero: nuevoPoloHero };
    case 'nuevo up':
      return { dark: true, hero: nuevoUpHero };
    case 'nuevo passat':
      return { dark: true, hero: passatHero };
    case 'polo':
      return { dark: true, hero: poloHero };
    case 'saveiro':
      return { dark: true, hero: saveiroHero };
    case 'scirocco':
      return { dark: true, hero: sciroccoHero };
    case 'sharan':
      return { dark: true, hero: sharanHero };
    case 'suran':
      return { dark: false, hero: suranHero };
    case 'tiguan':
      return { dark: true, hero: tiguanHero };
    case 'touareg':
      return { dark: true, hero: touaregHero };
    case 'up':
    case 'up!':
      return { dark: true, hero: upHero };
    case 'vento':
    case 'nuevo vento':
      return { dark: true, hero: ventoHero };
    case 'nuevo virtus':
    case 'virtus':
      return { dark: false, hero: virtusHero };
    case 'voyage':
      return { dark: true, hero: voyageHero };
    case 'tcross':
    case 't-cross':
      return { dark: true, hero: tcrossHero };

    case 'nueva frontier':
      return { dark: true, hero: nuevaFrontierHero };
    case 'np300 frontier':
      return { dark: true, hero: frontierHero };
    case 'nueva kicks':
      return { dark: true, hero: nuevoKicksHero };
    case 'kicks':
      return { dark: true, hero: kicksHero };
    case 'march':
      return { dark: true, hero: marchHero };
    case 'murano':
      return { dark: true, hero: muranoHero };
    case 'note':
      return { dark: true, hero: noteHero };
    case 'nuevo sentra':
      return { dark: true, hero: nuevoSentraHero };
    case 'sentra':
      return { dark: true, hero: sentraHero };
    case 'nuevo versa':
      return { dark: true, hero: nuevaVersaHero };
    case 'versa':
      return { dark: true, hero: versaHero };
    case 'x trail':
      return { dark: true, hero: xtrailHero };
    case 'nueva x trail':
      return { dark: true, hero: nuevaXtrailHero };
    case 'bj40':
    case 'bj40 conora':
      return { dark: false, hero: bj40 };
    case 'x35':
    case 'x35 luxury':
    case 'x35 fashion':
      return { dark: false, hero: x35 };
    case 'x55':
    case 'x55 luxury':
      return { dark: false, hero: x55 };
    default:
      console.log('Image not found for ', family);
      return {};
  }
};
